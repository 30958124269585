import React from "react";
import { ErrorBoundary } from './components/ErrorBoundry';
import {RouterProvider} from "react-router-dom";
import {router} from './router';

function App() {
  return (
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
  );
}

export default App;
