import axios from '../utils/axiosConfig';

export const authService = {
    logout: async () => {
        const currentPath = window.location.pathname;
        console.log(currentPath);
        if(currentPath !== '/'
            && currentPath !== '/login'
            && currentPath !== '/sso/okta') {
            try {
                await axios.post('/auth/logout');
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error logging out', error);
            } finally {
                console.log(" USER LOGGED OUT")
                window.location.href = '/login';
            }
        }
    },

    checkAuthStatus: async () => {
    try {
        const response = await axios.get('/auth/me');
        if (response.data && response.data.userInfo && response.data.expires_in) {
            return {
                isAuthenticated: true,
                user: response.data.userInfo,
                expires_in: response.data.expires_in,
            };
        }
        return { isAuthenticated: false, user: null };
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching auth status', error);
        return { isAuthenticated: false, user: null };
    }
},

refreshToken: async ()=> {
    try {
        const response = await axios.post('/auth/refresh');
        return response.data;
    } catch (error) {
        console.log('Session refresh failed. Please log in again.');
        throw error;
    }
},
};
