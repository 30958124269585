import { combineReducers } from "@reduxjs/toolkit";
import { reducer as userReducer } from "../slices/user";
import { reducer as roleReducer } from "../slices/role";
import { reducer as notificationsReducer } from "../slices/notifications";

const appReducer = combineReducers({
  user: userReducer,
  role: roleReducer,
  notifications: notificationsReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
