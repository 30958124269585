import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { authService } from '../services/authService';

export const AuthContext = createContext({
    isAuthenticated: false,
    user: null,
    isLoading: true,
    login: () => {},
    logout: async () => {},
    checkAuthStatus: async () => {},
    refreshToken: async () => {},
    tokenExpiry: null,
    refreshPromptShown: false,
    setRefreshPromptShown: () => {},
});

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(  null);
    const [isLoading, setIsLoading] = useState(true);
    const [tokenExpiry, setTokenExpiry] = useState(null);
    const [refreshPromptShown, setRefreshPromptShown] = useState(false);

    const timerIdRef = useRef(null);

    const startTokenTimer = useCallback((expiresIn) => {
        if (timerIdRef.current) {
            clearTimeout(timerIdRef.current);
        }
        const expiryTime = Date.now() + expiresIn * 1000;
        setTokenExpiry(expiryTime);

        const timeoutDuration = (expiresIn - 900) * 1000; // 15 minutes before expiration

        if (timeoutDuration > 0) {
            const id = setTimeout(() =>   refreshToken(), timeoutDuration);
            timerIdRef.current = id;
        }
    }, []);

    const checkAuthStatus = useCallback(async () => {
        setIsLoading(true);
        try {
            const { isAuthenticated, user, expires_in } = await authService.checkAuthStatus();
            setIsAuthenticated(isAuthenticated);
            setUser(user);
            if (isAuthenticated && expires_in) {
                startTokenTimer(expires_in);
            }
        } catch (error) {
            setIsAuthenticated(false);
            setUser(null);
        } finally {
            setIsLoading(false);
        }
    }, [startTokenTimer]);

    const logout = useCallback(async () => {
        await authService.logout();
        setIsAuthenticated(false);
        setUser(null);
        setTokenExpiry(null);
    }, []);

    const refreshToken = useCallback(async () => {
        try {
            const { expires_in } = await authService.refreshToken();
            startTokenTimer(expires_in);
            console.log('Session refreshed successfully!');
            setRefreshPromptShown(false); // Hide the prompt after successful refresh
        } catch (error) {
            let errorMessage = 'An unexpected error occurred.';
            if (axios.isAxiosError(error) && error.response?.data?.message) {
                errorMessage = error.response.data.message;
            } else if (error instanceof Error) {
                errorMessage = error.message;
            }
            console.log(errorMessage);
            await logout(); // Log out the user on failure
        }
    }, [startTokenTimer, logout]);

    const login = useCallback(
        (userInfo, expiresIn) => {
            setIsAuthenticated(true);
            setUser(userInfo);
            startTokenTimer(expiresIn);
        },
        [startTokenTimer]
    );

    // Initial auth check on mount
    useEffect(() => {
        checkAuthStatus();
    }, [checkAuthStatus]);

    const authContextValue = useMemo(
        () => ({
            isAuthenticated,
            user,
            isLoading,
            login,
            logout,
            checkAuthStatus,
            refreshToken,
            tokenExpiry,
            refreshPromptShown,
            setRefreshPromptShown,
        }),
        [
            isAuthenticated,
            user,
            isLoading,
            login,
            logout,
            checkAuthStatus,
            refreshToken,
            tokenExpiry,
            refreshPromptShown,
            setRefreshPromptShown,
        ]
    );

    return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};
