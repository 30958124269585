import axios from 'axios';
import { authService } from '../services/authService';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_GATEWAY,
    withCredentials: true,
    timeout: 50000,
});

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response?.status === 401) {
            console.log('Your session has expired.');
            await authService.logout();
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
