import React, { Component } from 'react';


export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can log error information here, e.g., send it to an error tracking service
        // eslint-disable-next-line no-console
        console.error('Error caught by ErrorBoundary:', error, errorInfo);
    }

    handleReload = () => {
        this.setState({ hasError: false });
        window.location.reload();
    };

    render() {
        if (this.state.hasError) {
            // Fallback UI when an error occurs
            return (
                <div style={{ textAlign: 'center', padding: '2rem' }}>
                    <h1>Something went wrong.</h1>
                    <p>We're sorry for the inconvenience. Please try reloading the page.</p>
                    <button type="button" onClick={this.handleReload} style={{ marginTop: '1rem' }}>
                        Reload Page
                    </button>
                </div>
            );
        }

        return this.props.children;
    }
}
