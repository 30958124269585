import React from 'react';
import {createBrowserRouter, Navigate} from 'react-router-dom';
import { AuthProvider } from './contexts/authContext';
import MainLayout from './layout/MainLayout';
import AuthLayout from './layout/AuthLayout';
import {Admin, CostTool, Login, NewQuote, QuoteRequest, SearchQuotes, SSOReturn, Templates} from "./pages";
import {useAuth} from "./hooks/useAuth";

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth();

    if (!isAuthenticated && !isLoading) {
        return <Navigate to="/login" replace />;
    }

    return children;
};

export const router = createBrowserRouter([
    {
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: (
                    <Login />
                ),
            },
            {
                path: 'login',
                element: (
                    <Login />
                ),
            },
            {
                path: 'sso/okta',
                element: (
                    <SSOReturn />
                ),
            },
        ],
    },
    {
        element: (
            <AuthProvider>
                <AuthLayout />
            </AuthProvider>
        ),
        children: [
            {
                path: '/costTool',
                element: (
                    <ProtectedRoute>
                        <CostTool />
                    </ProtectedRoute>
                ),
            },
            {
                path:"/quoteTool/newQuote",
                element:(
                    <ProtectedRoute>
                        <NewQuote />
                    </ProtectedRoute>
                )
            },
            {
                path:"/quoteTool/search",
                element:(
                    <ProtectedRoute>
                        <SearchQuotes />
                    </ProtectedRoute>
                )
            },
            {
                path:"/quoteTool/quoteRequest",
                element: (
                    <ProtectedRoute>
                        <QuoteRequest />
                    </ProtectedRoute>
                )
            },
            {
                path:"/quoteTool/templates",
                element: (
                    <ProtectedRoute>
                        <Templates />
                    </ProtectedRoute>
                )
            },
            {
                path:"/admin",
                element: (
                    <ProtectedRoute>
                        <Admin />
                    </ProtectedRoute>
                )
            }
        ],
    },
]);
