import React from "react";
import {Provider as ReduxProvider} from "react-redux";
import {SettingsProvider} from "./contexts/settingsContext";
import {UserProvider} from "./contexts/userContext";
import {createRoot} from 'react-dom/client';
import store from "./store";
import App from "./app";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import './index.css';
import {QuoteRequesetProvider} from "./contexts/quoteRequestContext";
import {AuthProvider} from "./contexts/authContext";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
        <ReduxProvider store={store}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <AuthProvider>
                    <SettingsProvider>
                        <UserProvider>
                            <QuoteRequesetProvider>
                                    <App/>
                                    { /* react dev tools <ReactQueryDevtools initialIsOpen={false} /> */}
                            </QuoteRequesetProvider>
                        </UserProvider>
                    </SettingsProvider>
                </AuthProvider>
            </LocalizationProvider>
        </ReduxProvider>);


