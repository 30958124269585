import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import CircularProgress from "@mui/material/CircularProgress";
import {Box} from "@mui/material";

function SSOReturn() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { login } = useAuth();
    const code = searchParams.get('code');

    useEffect(() => {
        if (!code) {
            console.log('Missing authorization code. Please try logging in again.');
            navigate('/Login');
            return;
        }

        axios
            .post(
                `${process.env.REACT_APP_API_GATEWAY}/auth/callback`,
                { code },
                { withCredentials: true }
            )
            .then((response) => {
                if (response.data?.success && response.data.userInfo && response.data.expires_in) {
                    login(response.data.userInfo, response.data.expires_in);
                    navigate('/CostTool'); // Redirect to home on success
                } else {
                    throw new Error('Unexpected response format from server');
                }
            })
            .catch((error) => {
                console.log(
                    error +
                    ' An error occurred during the token exchange. Please try again.'
                );
                navigate('/Login');
            });
    }, [login, navigate, code]);

    return (
        <Box sx={{position: 'absolute', left: '50%'}}>
            <CircularProgress/>
        </Box>
    );
}

export default SSOReturn;
