/* eslint-disable camelcase */
import axios from "../../utils/axiosConfig";

const context = "/sendErrorEmail";

export default {
    post: async (postObj) => {
        console.log(postObj)
        try {
            const response = await axios.post(context, JSON.stringify(postObj));
            return response?.data;
        } catch (e) {
            throw e
        }
    },
};
