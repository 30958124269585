import * as React from 'react';
import {Outlet, useNavigate, useLocation} from 'react-router-dom';
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Logo from "../../assets/CostEngineLogo.png";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props} style={{marginTop: '200px'}}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                MATSON ‐ All Rights Reserved
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme({
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 640,
            full: 1100
        },
    }
});

let drawerWidth = 20;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const defaultTheme = createTheme();


export default function Layout() {
    const bannerLabel = 'Cost Engine Login';

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position="absolute" open={false}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                            background: 'white'
                        }}
                    >
                        <Box
                            sx={{marginTop: 0, flexGrow: 1}}
                        >
                            <img src={Logo} alt="Cost Engine Logo" style={{ height: '45px'}}/>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box
                    component="main"
                    className='box-main'
                >
                    <Toolbar/>
                    <Box sx={{flexGrow: 1}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} style={{textAlign: 'right', padding: '40px 30px 40px'}} sx={{justifyContent: 'flex-end'}}>
                                <Typography variant="h5" style={{fontWeight: 'bold', color: '#969696', marginRight: '2px'}}>{bannerLabel}</Typography>
                            </Grid>
                            <Grid item xs={12} className='main-body'>
                                <Outlet/>
                            </Grid>
                        </Grid>
                    </Box>
                    <Copyright sx={{pt: 4, textAlign: "center"}}/>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
