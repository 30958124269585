import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import {Button} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

function Login() {
    const location = useLocation();
    const navigate = useNavigate();

    const startLogin = () => {
        const authBaseUrl=process.env.REACT_APP_COGNITO_DOMAIN
        const clientId=process.env.REACT_APP_COGNITO_CLIENT_ID
        const redirectUri=process.env.REACT_APP_REDIRECT_URI
        const scope=process.env.REACT_APP_SCOPE
        const loginUrl = `${authBaseUrl}/oauth2/authorize?client_id=${clientId}&response_type=code&scope=${encodeURIComponent(
            scope
        )}&redirect_uri=${encodeURIComponent(redirectUri)}`;
        window.location.href = loginUrl;
    }

    return (
        <div style={{padding: '45px', textAlign: 'center'}}>
            Please login below.<br/><br/><br/>
                <Button
                    variant="contained"
                    startIcon={<RestartAltIcon/>}
                    style={{marginRight: '15px'}}
                    onClick={startLogin}
                >
                    login
                </Button>
        </div>
    );
}

export default Login;