/* eslint-disable camelcase */
import axios from "../../utils/axiosConfig";

const context = "/userActivity";

export default {
  post: async (postObj) => {
    console.log(postObj)
    try {
      const response = await axios.post(context, JSON.stringify(postObj));
      return response?.data;
    } catch (e) {
      throw e
    }
  },
  get: async (postObj) => {
    console.log(postObj)
    try {
      const response = await axios.get(context, postObj);
      return response?.data;
    } catch (e) {
      throw e
    }
  },
};
